<template>
  <section id="services-home" class="hidden lg:block pt-16 py-24 overflow-hidden relative">
    <svg id="circle" fill="#ffffff" viewBox="0 0 4050 4050" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2025" cy="2025" r="5040"></circle>
    </svg>
    <div class="2xl:max-w-7xl lg:max-w-6xl w-full px-4 lg:px-0 mx-auto relative z-50">
      <h3 class="text-4xl lg:text-6xl text-slate-900 text-center lg:text-right mr-2 mb-16 lg:mb-12">
        {{ $t('pages.home.hero.our-services') }}</h3>

      <div class="w-full" id="home-slider">
        <carousel :items-to-show="2" :items-to-scroll="1" :wrap-around="true" snap-align="start" class="my-2" ref="carousel"
                  @slide-start="disableButton('start')"
                  @slide-end="disableButton('end')">
          <slide key="1">
            <nuxt-link :aria-label="$t('pages.services.smalltexts.custom_web_development')"
                       :to="localePath('/services/custom-web-development')"
                       class="slide slide-scale text-left w-full bg-dark-blue mx-6 services-box relative p-8 mt-8">
              <div class="absolute inset-0 z-0 w-full h-full overflow-hidden pointer-events-none">
                <div class="radial-scale white-radial"></div>
                <div class="radial-scale pink-radial"></div>
              </div>
              <h4 class="text-2xl mb-3">{{ $t('pages.services.smalltexts.custom_web_development') }}</h4>
              <p class="max-w-sm leading-8">{{ $t('pages.services.smalltexts.custom_web_development_description') }}</p>
              <NuxtImg provider="cloudinary" loading="lazy" densities="x1" sizes="lg:900px" width="550" height="550"
                       format="webp" src="/images/new_customweb2.png" alt="Custom Web development"
                       class="absolute bottom-0 service-img service-img1"/>
            </nuxt-link>
          </slide>
          <slide key="2">
            <div class="slide text-right flex flex-col w-full h-full justify-between mr-6">
              <nuxt-link :aria-label="$t('pages.services.smalltexts.mobile_applications')"
                         :to="localePath('/services/mobile-apps')"
                         class="w-full slide-scale bg-dark-blue h-1/2 mx-4 mb-10 p-8 relative mt-8 z-20">
                <div class="absolute inset-0 z-0 w-full h-full overflow-hidden pointer-events-none">
                  <div class="radial-scale pink-radial"></div>
                </div>
                <h4 class="text-2xl mb-3">{{ $t('pages.services.smalltexts.mobile_applications') }}</h4>
                <p class="max-w-xs pl-16 leading-8 ml-auto">
                  {{ $t('pages.services.smalltexts.mobile_applications_description') }}</p>
                <NuxtImg provider="cloudinary" loading="lazy" width="300" height="300" format="webp"
                         src="/images/new_apps2.png" alt="Mobile apps"
                         class="absolute bottom-0 service-img service-img2"/>
              </nuxt-link>
              <div id="circle-trigger"></div>

              <nuxt-link :aria-label="$t('pages.services.smalltexts.admin_interfaces')"
                         :to="localePath('/services/custom-interfaces')"
                         class="text-left slide-scale w-full bg-dark-blue mx-4 p-8 h-1/2 relative">
                <div class="absolute inset-0 z-0 w-full h-full overflow-hidden pointer-events-none">
                  <div class="radial-scale white-radial"></div>
                </div>
                <h4 class="text-2xl mb-3 max-w-[300px]">{{ $t('pages.services.smalltexts.admin_interfaces') }}</h4>
                <p class="max-w-xs leading-8 pr-16">{{
                    $t('pages.services.smalltexts.admin_interfaces_description')
                  }}</p>
                <NuxtImg provider="cloudinary" loading="lazy" width="320" height="320" format="webp"
                         src="/images/new_admin.png" alt="Admin interfaces"
                         class="absolute bottom-0 service-img service-img3"/>
              </nuxt-link>
            </div>
          </slide>
          <slide key="3">
            <nuxt-link :aria-label="$t('pages.services.smalltexts.automation')" :to="localePath('/services/automation')"
                       class="slide slide-scale text-left w-full bg-dark-blue ml-8  mr-6 p-12 services-box relative shadow-xl p-8 mt-8">
              <div class="absolute inset-0 z-0 w-full h-full overflow-hidden pointer-events-none">
                <div class="radial-scale pink-radial"></div>
              </div>
              <h4 class="text-2xl mb-3">{{ $t('pages.services.smalltexts.automation') }}</h4>
              <p class="max-w-sm leading-8">{{ $t('pages.services.smalltexts.automation_description') }}</p>
              <NuxtImg provider="cloudinary" loading="lazy" width="500" height="500" format="webp"
                       src="/images/new_automation2.png" alt="Automation"
                       class="absolute bottom-0 service-img service-img4"/>
            </nuxt-link>
          </slide>
          <slide key="4">
            <div class="slide text-left flex flex-col w-full h-full justify-between mr-6">
              <nuxt-link :aria-label="$t('pages.services.smalltexts.vr_ar_experiences')"
                         :to="localePath('/services/vr-experiences')"
                         class="w-full slide-scale bg-dark-blue h-1/2 mx-4 mb-10 p-8 mt-8 relative">
                <div class="absolute inset-0 z-0 w-full h-full overflow-hidden pointer-events-none">
                  <div class="radial-scale white-radial"></div>
                </div>
                <h4 class="text-2xl mb-3">{{ $t('pages.services.smalltexts.vr_ar_experiences') }}</h4>
                <p class="max-w-xs pr-8 leading-8">{{
                    $t('pages.services.smalltexts.vr_ar_experiences_description')
                  }}</p>
                <NuxtImg provider="cloudinary" loading="lazy" width="300" height="300" format="webp"
                         src="/images/new_ar_large.png" alt="AR experiences"
                         class="absolute bottom-0 service-img service-img5"/>

              </nuxt-link>
              <nuxt-link :aria-label="$t('pages.services.smalltexts.cloud_infrastructure')"
                         :to="localePath('/services/cloud-infrastructure')"
                         class="slide slide-scale text-right w-full bg-dark-blue mx-4 p-8 h-1/2 relative">
                <div class="absolute inset-0 z-0 w-full h-full overflow-hidden pointer-events-none">
                  <div class="radial-scale white-radial"></div>
                </div>
                <h4 class="text-2xl mb-3">{{ $t('pages.services.smalltexts.cloud_infrastructure') }}</h4>
                <p class="max-w-xs leading-8 ml-auto pl-16">
                  {{ $t('pages.services.smalltexts.cloud_infrastructure_description') }}</p>
                <NuxtImg provider="cloudinary" loading="lazy" width="305" height="305" format="webp"
                         src="/images/new_cloud_large5.png" alt="Cloud infrastructure"
                         class="absolute bottom-0 service-img service-img6"/>
              </nuxt-link>
            </div>
          </slide>
          <template #addons>
            <pagination/>
          </template>
        </carousel>
        <div class="controls ml-4 mt-8 flex items-center">
          <nuxt-link :aria-label="$t('pages.services.smalltexts.browse_all_services')" class="button button-outline"
                     :to="localePath('/services')">
            <span></span>{{ $t('pages.services.smalltexts.browse_all_services') }}
          </nuxt-link>
          <button @click="slide('prev')" class="button button-outline">
            <span></span><img src="~/assets/images/left-arrow.svg" alt="Previous Slide"></button>
          <button @click="slide('next')" class="button button-outline">
            <span></span><img src="~/assets/images/right-arrow2.svg" alt="Next Slide"></button>
        </div>
      </div>

    </div>
  </section>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel'

const {$gsap, $ScrollTrigger} = useNuxtApp()

export default {
  data: () => {
    return {
      circle: {
        cx: "2025",
        cy: "2025",
        r: "5040",
        viewBox: "0 0 4050 4050",
      },
      settings: {
        itemsToShow: 2,
        snapAlign: 'start',
        itemsToScroll: 1,
      }
    }
  },
  components: {
    Carousel, Slide, Pagination
  },
  methods: {
    slide(direction) {
      if (direction === 'next') {
        this.$refs.carousel.next()
      } else {
        this.$refs.carousel.prev()
      }
    },
    disableButton(direction) {
    }
  },
  mounted() {
    if (window.screen.width >= 1024) {
      if (window.screen.width < 1400) {
        this.circle = {
          cx: "1525",
          cy: "1525",
          r: "3040",
          viewBox: "0 0 3050 3050",
        }
      }

      const circlePop = $gsap.timeline({
        scrollTrigger: {
          trigger: "#circle-trigger",
          pin: false,
          start: "top bottom",
          markers: false,
          scrub: false,
          once: false,
          direction: 'normal',
          toggleActions: 'play none none reverse'
        }
      });
      const scaler = window.screen.width * 3
      circlePop
          .add(() => console.log('circle'))
          .from("#circle circle", {scale: 0, duration: 0.5})
      // .to(".switcharoo", {color: '#383838'}, 'motion')
      // .to(".multi-card", {backgroundColor: '#ffffff', duration: 0.1}, 'motion')
      // .to(".gradient-background", {backgroundImage: 'linear-gradient(125deg, #b1bdef, #f8f8f8)'}, 'motion')
    }
  }
}
</script>
